.recentDives{
    width: 35rem;
    overflow-y: scroll;
    height:50vh;
    margin-left: 1rem;
    margin-right: 5rem;
    margin-bottom: 4rem;
    background-color: rgb(77,76,76,.7);
    border-radius: 5px;
    position: relative;
}

.logListContainer{
    padding-left: 1rem;
}

.recentDivesTop{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0px 7px 0px 7px;
    
}

#logListItem{
    border-bottom: 1px solid white;
}

.viewAllBtn{
    margin-left: 17rem;
    background: none;
    border: none;
    text-decoration: underline;
    color: #7796CB;
    
}

.viewAllBtnLink{
    align-self: center;
}
.addLogBtn, a{
margin-left: 1rem;
color:gray;

}
.addLogBtn{
    color: #FAF0E6;
    font-weight: 900;
}

.log-link{
    cursor: pointer;
    text-decoration: none;
    color:white;
    align-self: center;
    
}

.plusIcon{
    color: white;
}



.add-log-link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color:black;
    height:15rem;
    width: 15rem;
    background-color: rgb(77,76,76,.7);
    margin-top: 1rem;
    padding:3px;
    text-align: center;
}
.log-link-all{
    text-decoration: none;
    color:black;
    height:15rem;
    width: 15rem;
    background-color: rgb(77,76,76,.7);
    margin-top: 1rem;
    padding:3px;
    text-align: center;
    
}

.logText{
    align-self: center;
}

.allDiveLogs{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    
}

.diveLogDetailsContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailsLeft{
    margin:2rem;
    width:100%;
    height:28rem;
    background-image: url("../images/LogImg.png");
    background-size: 100%;
    background-position: center;
    
}

.detailsRight{
    display: flex;
    width: 100%;
    border-top: 1px white solid;
}


.dlSi, .dlPgEnter, .dlPgExit, .dlBt, .dlMaxDepth, .dlAvgDepth{
    position: relative;
}

.dlSi{
    top: 70px;
    left: 8px;
}

.dlPgEnter{
    top: 2rem;
    left: 9rem;
}

.dlBt{
    top: 44%;
    left: 40%;
}

.dlAvgDepth{
    left:7rem;
}
.dlMaxDepth{
    bottom: -155px;
    left: 250px;

}

.dlPgExit{
    left: 70%;
}


.infoContent{
    width:50%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    margin:auto;
    padding: 2rem;
}

.tankInfo{
    background-color: rgb(77,76,76,.7);
    width:100%;
    border-radius: 5px;
    padding:1rem;
    
}

.suitInfo{
    width:100%;
    margin-top: 2rem;
    padding:1rem;
    border-radius: 5px;
    background-color: rgb(77,76,76,.7);
    
}

.weatherComments{
    width:50%;
    height: auto;
    margin-top: 2rem;
    margin-left: 1rem;
    padding:1rem;
    background-color: rgb(77,76,76,.7);
    border-radius: 5px;
    
}

.wContainer{
    display: flex;
    flex-direction: column;
    
}

.buddyInfo{
    display: flex;
    justify-content: space-around;
    margin:1rem;
    background-color: rgb(77,76,76,.7);
    border-radius: 5px;
}

.form-group{
    
    width: 20rem;
    color:white;
    margin-right:2rem;
}

.diveLogForm{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.btnAddLog{
    background-color: rgb(77,76,76,.7);
    width:20rem;
    height:4rem;
    color: #53a653;
    align-self: baseline;
}

.addNewBtn{
    color: white;
}

.backToDash{
    display: flex;  
    flex-direction: row;
}

.editDelContainer{
    width: 50%;
    display: flex;
   margin-top:2rem;
}

.delBtn{
    margin-left: 2rem;
}