body{
    background-color: #30343F;
    margin:1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 2px;
  color:#FAF0E6
}

button{
    color: #7796CB
}

.header{
    height:5rem;
    display: flex;
    justify-content: space-between;
}
.logo{
    height: 10rem;
    width:10rem;
    margin-top: -2rem;
}

.logout-btn{
    margin-top: 1rem;
    margin-right: 1rem;
    background-color: #7796CB;
    color: #FAF0E6
}

.container{
    display:flex;
}

.map{
    height: 30rem;
}